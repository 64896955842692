<template>
  <div class="minView">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5 col-md-offset-3">
          <div class="row mt-topp">
            <div class="col-lg-12">
              <div class="text-center mb-5">
                <img src="/melior_dk.svg" height="50" class="mb-4" alt="" />
                <p class="text-dark">Sign in to access portal</p>
              </div>
              <form id="login-form" @submit.prevent="submitForm" role="form">
                <div class="form-group">
                  <label class="text-dark" to="email">Email address</label>
                  <input
                    v-model="email"
                    required
                    type="email"
                    name="email"
                    tabindex="1"
                    id="email"
                    class="form-control"
                  />
                  <!-- <p
                    v-if="formErrors.email.length > 0"
                    class="small text-danger mt-1"
                  >
                    {{ formErrors.email }}
                  </p> -->
                </div>
                <div class="form-group">
                  <label class="text-dark" to="password">Password</label>
                  <input
                    type="password"
                    required
                    v-model="password"
                    name="password"
                    id="password"
                    tabindex="2"
                    class="form-control"
                  />
                  <!-- <p
                    v-if="formErrors.password.length > 0"
                    class="small text-danger mt-1"
                  >
                    {{ formErrors.password }}
                  </p> -->
                </div>
                <div class="text-right">
                  <router-link
                    to="/forgot-password"
                    class="text-primary text-underline"
                  >
                    Forgot password?
                  </router-link>
                </div>

                <div class="form-group text-center mt-5">
                  <button
                    :disabled="loading"
                    type="submit"
                    class="btn btn-primary btn-block btn-site px-5"
                  >
                    Login
                    <BtnLoading
                      v-if="loading"
                      class="d-inline-block"
                      height="18"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
export default {
  components: { BtnLoading },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      formErrors: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      this.loading = true;
      let data = {
        email: this.email,
        password: this.password,
      };
      let payload = {
        data,
        path: "/auth/login",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.loading = false;

          let { token, user } = resp.data.data;
          // let user = resp.data.payload.user;
          token = this.CryptoJS.AES.encrypt(
            token.access_token,
            this.$passPhrase
          ).toString();
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();
          localStorage.setItem("token", token);
          localStorage.setItem("user", user);
          this.$toast.success(
            "Login",
            "Login successful.",
            this.$toastPosition
          );

          setTimeout(() => {
            window.location.href = "/incoming-release";
          }, 1000);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.formErrors = err.response.data.payload;
            this.$toast.info(
              "Login",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Login",
              "Unable to login, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
